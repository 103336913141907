import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import App from './App/App';

import './assets/base.scss';

ReactDOM.render(
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </QueryParamProvider>
  </Router>,
  document.getElementById('root')
);
