import React from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

import Step from './Step';
import Tag from '../Tag/Tag';
import styles from './Step.module.scss';

import scriptSectionStyles from '../ScriptSection/ScriptSection.module.scss';

const Embed = ({ choice, tags }) => {
  const [expanded, setExpanded] = React.useState(false);
  const toggle = () => setExpanded(!expanded);
  if (expanded) {
    return (
      <div className="d-flex flex-column align-items-start">
        <div className="mb-2">
          <Button
            color="link"
            onClick={toggle}
            className={cx(styles.highlightBtn, 'mr-2')}
            title="Hide highlight"
          >
            <FontAwesomeIcon icon={faVolumeMute} />
          </Button>
          {tags}
        </div>
        <iframe
          title={`LVN highlight ${choice.id}`}
          src={`https://embed.lvn.org/?hid=${choice.id}`}
          className={scriptSectionStyles.embed}
          style={{ width: '35rem' }}
        />
      </div>
    );
  }
  return (
    <div>
      <Button
        color="link"
        onClick={toggle}
        className={cx(styles.highlightBtn, 'mr-2')}
        title="Show highlight"
      >
        <FontAwesomeIcon icon={faVolumeUp} />
      </Button>
      {tags}
    </div>
  );
};

function toggleArrayItem(arr, item) {
  if (!arr) {
    arr = [];
  }
  return arr.includes(item)
    ? arr.filter((i) => i !== item) // remove item
    : [...arr, item]; // add item
}

const MultipleChoiceStep = ({
  title,
  header,
  backPath,
  onNext,
  choices,
  selection,
  handleChangeSelect,
  children,
  nextText,
  type,
  legend,
  percent,
}) => {
  return (
    <Step
      title={title}
      header={header}
      backPath={backPath}
      nextText={nextText}
      onNext={onNext}
      percent={percent}
    >
      {children}
      <fieldset>
        {legend && (
          <legend className={cx(styles.legend, 'font-weight-bold mb-1')}>
            {legend}
          </legend>
        )}
        <FormGroup className="mt-1">
          {choices.map((choice) => {
            const isActiveChoice = selection
              ? !!selection.find((d) => d === choice.id)
              : false;

            if (type !== 'highlights') {
              return (
                <div
                  key={choice.id}
                  className={cx(styles.choice, {
                    [styles.active]: isActiveChoice,
                  })}
                >
                  <Label>
                    <Input
                      type="checkbox"
                      onChange={() =>
                        handleChangeSelect(
                          toggleArrayItem(selection, choice.id)
                        )
                      }
                      checked={isActiveChoice}
                    />
                    {Array.isArray(choice.text) ? (
                      <div className={styles.content}>
                        {choice.text.map((text, i) => (
                          <p key={i}>{text}</p>
                        ))}
                      </div>
                    ) : (
                      <p className={styles.content}>{choice.text}</p>
                    )}
                  </Label>
                  <div>
                    {choice.tags.map((tag) => (
                      <Tag
                        key={`${tag.key}-${choice.id}`}
                        tag={tag}
                        className={styles.content}
                      />
                    ))}
                  </div>
                </div>
              );
            }
            return (
              <div
                key={choice.id}
                className={cx(styles.choice, {
                  [styles.active]: isActiveChoice,
                })}
              >
                <Label>
                  <Input
                    type="checkbox"
                    onChange={() =>
                      handleChangeSelect(toggleArrayItem(selection, choice.id))
                    }
                    checked={isActiveChoice}
                  />
                  <div className={styles.content}>
                    <p>{choice.description}</p>
                  </div>
                </Label>
                <div className={styles.content}>
                  <Embed
                    choice={choice}
                    tags={
                      <>
                        {choice.tags.map((tag) => (
                          <Tag key={`${tag.key}-${choice.id}`} tag={tag} />
                        ))}
                      </>
                    }
                  />
                </div>
              </div>
            );
          })}
        </FormGroup>
      </fieldset>
    </Step>
  );
};

export default MultipleChoiceStep;
