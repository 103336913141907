import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import Script from '../Script/Script';
import PrintableScript from '../Script/PrintableScript';
import Scripts from '../Scripts/Scripts';
import ScriptSection from '../ScriptSection/ScriptSection';
import { scriptSections, DEFAULT_WELCOME } from '../constants';

// steps
import Step from '../CreationSteps/Step';
import StartStep from '../CreationSteps/StartStep';
import MultipleChoiceStep from '../CreationSteps/MultipleChoiceStep';
import TitleStep from '../CreationSteps/TitleStep';

// data
import { data as tagData } from '../data/tags';
import chunkData from '../data/chunks';
import highlightData from '../data/highlights';

import './App.scss';
import SingleChoiceStep from '../CreationSteps/SingleChoiceStep';

function findSection(sectionSlug) {
  return scriptSections.find((section) => section.slug === sectionSlug);
}

// how much each step is on progress bar
const progressBase = 12.5;

function App() {
  const [activeTopics, setActiveTopics] = React.useState([]);
  const [filteredChunkData, setFilteredChunkData] = React.useState(chunkData);
  const [filteredHighlightData, setFilteredHighlightData] = React.useState(
    highlightData
  );
  const [chosenChunkIds, setChosenChunkIds] = React.useState({});
  const [customWelcome, setCustomWelcome] = React.useState(DEFAULT_WELCOME);
  const history = useHistory();

  function generateHandleSelect(sectionKey) {
    return function (chunkIds) {
      setChosenChunkIds({ ...chosenChunkIds, [sectionKey]: chunkIds });
    };
  }

  function handleUpdateScript(title) {
    let scriptQueryString = '?';
    Object.entries(chosenChunkIds).forEach(([key, chunkIds]) => {
      const value = chunkIds.join('_');
      scriptQueryString += `${key}=${value}&`;
    });
    const titleString = title;
    const titleQueryString = `title=${encodeURIComponent(titleString)}`;
    const welcomeQueryString = `welcome=${encodeURIComponent(customWelcome)}`;
    history.push(
      `/script${scriptQueryString}${titleQueryString}&${welcomeQueryString}`
    );
  }

  function handleUpdateTopics(topics) {
    const chunkKeys = Object.keys(chunkData);
    const topicKeys = topics.map((topic) => topic.key);
    let newChunkData = {};
    chunkKeys.forEach((key) => {
      newChunkData[key] = chunkData[key].filter((chunk) => {
        return chunk.tags.some((d) => [...topicKeys, 'all'].includes(d));
      });
    });
    let newHighlightData = highlightData.filter((highlight) =>
      highlight.tags.some((d) => [...topicKeys, 'all'].includes(d))
    );
    setFilteredChunkData(newChunkData);
    setFilteredHighlightData(newHighlightData);
    setChosenChunkIds({});
    setActiveTopics(topics);
  }

  // TODO: generate routes from map
  const welcome = findSection('welcome');
  const purpose = findSection('purpose-and-housekeeping');
  const connectingQuestions = findSection('connecting-questions');
  const storyForStarters = findSection('story-for-starters');
  const communityFocusedConversation = findSection(
    'community-focused-conversation'
  );
  const connectingAcrossCommunity = findSection('connecting-across-community');
  const closingQuestion = findSection('closing-question');

  return (
    <Switch>
      <Route path="/script">
        <Script data={chunkData} highlightData={highlightData} />
      </Route>
      <Route path="/printable-script">
        <PrintableScript data={chunkData} highlightData={highlightData} />
      </Route>
      <Route path="/start">
        <StartStep
          topics={tagData}
          activeTopics={activeTopics}
          setActiveTopics={handleUpdateTopics}
          percent={1}
        />
      </Route>
      <Route path={`/${welcome.slug}`}>
        <Step
          title={welcome.display}
          header={welcome.display}
          backPath="/greeting"
          nextPath="/purpose-and-housekeeping"
          percent={progressBase * 1}
        >
          Before you start recording, you should introduce your project and
          organization. It might look something like this, but feel free to add
          your own language:
          <ScriptSection
            key={welcome.slug}
            script={welcome.script}
            speakerInstructions={welcome.speakerInstructions}
            className="mt-4"
            labeled
            editable
            customWelcome={customWelcome}
            setCustomWelcome={setCustomWelcome}
          />
        </Step>
      </Route>
      <Route path={`/${purpose.slug}`}>
        <Step
          title={purpose.display}
          header={purpose.display}
          backPath="/welcome"
          nextPath="/connecting-questions"
          percent={progressBase * 2}
        >
          {purpose.notes}
          <ScriptSection
            key={purpose.slug}
            script={purpose.script}
            speakerInstructions={purpose.speakerInstructions}
            className="mt-4"
            labeled
          />
        </Step>
      </Route>
      <Route path={`/${connectingQuestions.slug}`}>
        <Step
          title={connectingQuestions.display}
          header={connectingQuestions.display}
          backPath="/purpose-and-housekeeping"
          nextPath="/story-for-starters"
          percent={progressBase * 3}
        >
          {connectingQuestions.notes}
          <ScriptSection
            key={connectingQuestions.slug}
            script={connectingQuestions.script}
            speakerInstructions={connectingQuestions.speakerInstructions}
            className="mt-4"
            labeled
          />
        </Step>
      </Route>
      <Route path={`/${storyForStarters.slug}`}>
        <SingleChoiceStep
          title={storyForStarters.display}
          header={storyForStarters.display}
          backPath="/connecting-questions"
          onNext={() => history.push('/community-focused-conversation')}
          choices={filteredChunkData.storyForStarters}
          selection={chosenChunkIds['storyForStarters']}
          handleChangeSelect={generateHandleSelect('storyForStarters')}
          legend="Select one of the following:"
          percent={progressBase * 4}
        >
          <>{storyForStarters.notes}</>
        </SingleChoiceStep>
      </Route>
      <Route path={`/${communityFocusedConversation.slug}`}>
        <SingleChoiceStep
          title={communityFocusedConversation.display}
          header={communityFocusedConversation.display}
          backPath="/story-for-starters"
          onNext={() => history.push('/connecting-across-community')}
          choices={filteredChunkData.communityFocusedConversation}
          selection={chosenChunkIds['communityFocusedConversation']}
          handleChangeSelect={generateHandleSelect(
            'communityFocusedConversation'
          )}
          legend="Select one of the following:"
          percent={progressBase * 5}
        >
          {communityFocusedConversation.notes}
        </SingleChoiceStep>
      </Route>
      <Route path={`/${connectingAcrossCommunity.slug}`}>
        <MultipleChoiceStep
          title={connectingAcrossCommunity.display}
          header={connectingAcrossCommunity.display}
          backPath="/community-focused-conversation"
          onNext={() => history.push('/closing-question')}
          choices={filteredHighlightData}
          selection={chosenChunkIds['connectingAcrossCommunity']}
          handleChangeSelect={generateHandleSelect('connectingAcrossCommunity')}
          type="highlights"
          percent={progressBase * 6}
        >
          {connectingAcrossCommunity.notes}
          <strong>Select a highlight you'd like to share:</strong>
        </MultipleChoiceStep>
      </Route>
      <Route path={`/${closingQuestion.slug}`}>
        <MultipleChoiceStep
          title={closingQuestion.display}
          header={closingQuestion.display}
          backPath="/connecting-across-community"
          onNext={() => history.push('/title')}
          choices={filteredChunkData.closingQuestion}
          selection={chosenChunkIds['closingQuestion']}
          handleChangeSelect={generateHandleSelect('closingQuestion')}
          percent={progressBase * 7}
        >
          {closingQuestion.notes}
          <p className="font-weight-bold mb-1">
            Select between 1 - 4 questions. For conversations one hour or less
            choose 1, for longer conversations choose up to 4:
          </p>
        </MultipleChoiceStep>
      </Route>
      <Route path="/title">
        <TitleStep
          title="Add a title"
          header="That's a wrap!"
          backPath="/closing-question"
          onNext={handleUpdateScript}
          percent={progressBase * 8}
        />
      </Route>
      <Route path="/scripts">
        <Scripts />
      </Route>
      <Redirect to="/start" />
    </Switch>
  );
}

export default App;
