import React from 'react';

export const DEFAULT_WELCOME = `Welcome to this conversation of Your Organization and Cortico’s Local Voices Network. Thanks for agreeing to participate in this conversation!`;

// TODO: lol this is more than a constant at this point
export const scriptSections = [
  {
    slug: 'greeting',
    display: 'Pre-Meeting Greeting + Technology Acclimation',
    timeEstimate: '15 minutes ahead of posted time',
    speakerInstructions: (
      <>
        <p>
          LVN Staff will be on the call 15 minutes early to help orient new
          participants to Zoom.
        </p>
        <p>
          Together, help make people feel comfortable and welcome in what may be
          a stressful time or experience for them.
        </p>
      </>
    ),
    script: (
      <ul>
        <li>Welcome people as they join the call.</li>
        <li>
          As people join, let them know that if they do not want their name to
          appear on the screen, they can hover over their name and change it or
          delete it.
        </li>
        <li>
          Ask everyone to mute themselves and to keep themselves muted until
          they are speaking. (if they are on a phone *6 [star 6] mutes and
          unmutes.) Give people 15 seconds to experiment!
        </li>
        <li>
          Ask everyone to notice that there are two views (accessible at the top
          of the screen: Speaker View and Gallery view - If they want to see the
          whole group, they need gallery view.{' '}
        </li>
        <li>
          Tell them where to find the chat button, and request that they use the
          chat during the call to let you know if they have any questions or are
          experiencing technical difficulties.{' '}
        </li>
        <li>
          Ask each person to post where they are (neighborhood, region etc.) in
          the chat box.{' '}
        </li>
        <li>
          Let people know that if they would like to respond to a comment
          someone else has made, they can use the “raise hand” button to notify
          the facilitator. They can find the button by clicking “Participants.”
          After clicking that, they should see a blue raise hand button. Ask
          everyone to try it out.
        </li>
      </ul>
    ),
    notes: <></>,
  },
  {
    slug: 'welcome',
    display: 'Welcome & Project Summary',
    timeEstimate: '',
    speakerInstructions: <></>,
    script: (
      <>
        <p>
          Welcome to this conversation of <i>Your Organization</i> and Cortico’s
          Local Voices Network. Thanks for agreeing to participate in this
          conversation!
        </p>
      </>
    ),
    notes: <></>,
  },
  {
    slug: 'purpose-and-housekeeping',
    display: 'Introduction of Purpose & Housekeeping',
    timeEstimate: '5 minutes',
    speakerInstructions: (
      <>
        <p>
          This section can be read by the facilitator or by the LVN Staff
          Moderator when available.
        </p>
        <p style={{ marginTop: '28rem' }}>
          Please go around the group and have each person acknowledge this. It’s
          kind of like acknowledging that you are sitting in the emergency exit
          row on an airplane.
        </p>
        <p>
          If someone in the group does not want to agree, please thank them for
          their time and ask them kindly to discontinue their participation in
          the conversation.
        </p>
        <p>Take a moment to look at each person. Head nods are fine.</p>
        <p className="font-weight-bold">
          To transition to the next section, invite all participants to take a
          breath together.
        </p>
      </>
    ),
    script: (
      <>
        <p>
          We have begun recording at this time, and I need to share a little
          information with you before we begin our conversation. Following our
          conversation today, the video recording will be discarded, and the
          audio will become part of the LVN Collection.
        </p>
        <p>
          There are three main purposes for this conversation: First, we want to
          create a new space for community members to listen and learn about
          each other’s lives. Second, we want to connect across groups and build
          our relationships with one another. Third, we want to create a unique
          listening channel through which we will lift up the voices and needs
          of the community to candidates, local policy makers, and members of
          the media.
        </p>
        <p>
          To this end, we are inviting you to have a different type of
          conversation. Local Voices Network conversations are focused on
          sharing our personal stories from our lived experience, rather than
          beginning the conversation with our positions on issues. We are doing
          this in order to help build connections and to foster conversations
          that improve our understanding of one another.
        </p>
        <p>
          We want to make sure that everyone gets a chance both to share and to
          learn from others in this conversation, and to support those goals we
          have a few guidelines for our conversation. They’re also on the
          materials we sent with your confirmation email.
        </p>
        <ol>
          <li> Speak for yourself and out of your own experiences</li>
          <li> Allow others to speak for themselves</li>
          <li> Share the time - allow others to finish speaking, take turns</li>
          <li>Stay curious - ask honest questions</li>
          <li> Pause - take time for reflection</li>
          <li>
            {' '}
            Listen generously - assume good intentions while recognizing that
            your words have an impact
          </li>
          <li>
            {' '}
            When possible, close unnecessary programs, applications, and
            notifications and put your phone on silent.
          </li>
        </ol>
        <p>
          Is everyone on board with these guidelines? Is there anything you
          would like to add or change?
        </p>
        <p>
          For the ease of this virtual conversation, we are going to use a
          modified “circle” process where each person will take a turn. I’m
          posting the order in which people will be invited to respond in the
          chat box. I’ll help transition us from one person to the next. You can
          always pass, or pass and ask for us to come back to you.
        </p>
        <p>Does anyone have any questions about this process?</p>
      </>
    ),
    notes: (
      <>
        <p>
          This section is standardized across conversations to ensure that
          participants have a good understanding of who Local Voices Network is,
          and what our hopes are for this project.
        </p>
        <p>
          We include Conversation Guidelines at the beginning of every
          conversation to ensure we have mutually agreed upon norms for
          participation. This sets the tone for the conversation, and also
          serves as a tool for the facilitator to create a space where people
          are free to participate respectfully.
        </p>
        <p>
          These are LVN’s suggested guidelines, but if your group or
          organization has your own guidelines already established, it is ok to
          swap these out - <strong>just don’t skip this step.</strong>
        </p>
      </>
    ),
  },
  {
    slug: 'connecting-questions',
    display: 'Connecting Questions',
    timeEstimate: '5 minutes',
    speakerInstructions: (
      <>
        <p>
          If anyone is on the phone, please verbalize the names of people in the
          order that you plan to go. Let people know we’ll use the same order
          for each question.
        </p>
        <p>Post the order of participants in the chat box.</p>
        <p className="font-weight-bold">
          Facilitators, please do respond to this question to establish or
          further your connections with the group. Include your name, gender
          pronouns, and value, and please model a brief response.
        </p>
        <p>Pause for answers.</p>
      </>
    ),
    script: (
      <>
        <p>
          Ok, to begin, we want to do a quick round of introductions. Please
          share just your <strong>first name</strong> (or a pseudonym). In
          addition to your first name, please share:
        </p>
        <ul>
          <li className="list-style-none">
            ...a little bit of background info about who you are, like where you
            live, or what you do <strong>and</strong>
          </li>
          <li className="list-style-none">
            ...a value that is important to you and how it is related to what
            brought you here today.
          </li>
        </ul>
        <p>For this first round, I’ll start...</p>
      </>
    ),
    notes: (
      <p>
        One of the principles of LVN conversations is that we always{' '}
        <strong>lead with values</strong>. This allows participants who may not
        know each other to begin the conversation from a space of shared values.
        This sets the tone for full participation in the rest of the
        conversation.
      </p>
    ),
  },
  {
    key: 'storyForStarters',
    slug: 'story-for-starters',
    display: 'Story For Starters',
    timeEstimate: '12-15 minutes',
    speakerInstructions: (
      <>
        <p>
          Pause. Hold the space and allow people some quiet time to think before
          sharing. If you’d like to break the silence…
        </p>
        <p>
          Make clear this is optional - individuals in the group may opt-out if
          they wish.
        </p>
        <p>
          Please give everyone a chance to answer this, even if it is to say
          they prefer not to respond.
        </p>
        <p>
          In this section, we generally recommend facilitators <u>do not</u>{' '}
          participate; however, if you have a group that is struggling you can
          model this with your own story.
        </p>
      </>
    ),
    notes: (
      <>
        <p>The Story for Starters should serve two purposes:</p>
        <ol>
          <li>
            It will help people in the conversation get to know each other and
            build a foundation for meaningful conversation.{' '}
          </li>
          <li>
            It puts participants in the mindset of sharing stories from their
            personal experience which helps set the tone for the remainder of
            the conversation.
          </li>
        </ol>
        <p>
          In Restorative Justice Circles, this would be known as the
          “relationship building round.”
        </p>
      </>
    ),
  },
  {
    key: 'communityFocusedConversation',
    slug: 'community-focused-conversation',
    display: 'Community Focused Conversation',
    timeEstimate: '30 minutes',
    speakerInstructions: (
      <>
        <p>
          Ask one question at a time, allowing participants to pick up threads
          and ask each other follow-ups, making sure everyone has a chance to
          respond.{' '}
        </p>
        <p>
          If you have time here you might hone in on one or two topics or themes
          that rose to the top of people’s list. You might ask people to dig
          deeper into these topics or ask each other questions.
        </p>
      </>
    ),
    notes: (
      <>
        <p>
          Questions in this section are really the heart of your conversation.
          We spend the most amount of time in this part of the conversation. We
          intentionally always start with a more strengths-based question before
          we dive into concerns or challenges.
        </p>
        <p>
          This is the most challenging part of the script to keep participants
          engaging from their personal experience rather than stating opinions{' '}
          {`&`}
          talking points. For this reason, questions are always framed in such a
          way as to invite stories, and we don’t use prompts that ask “why do
          you think….?”
        </p>
      </>
    ),
  },
  {
    key: 'connectingAcrossCommunity',
    slug: 'connecting-across-community',
    display: 'Connecting Across Community',
    timeEstimate: '10 minutes',
    speakerInstructions: (
      <>
        <p>
          After a full round of responses to either or both of the above
          questions,{' '}
          <strong>
            play an audio highlight from a participant in a past conversation.
          </strong>{' '}
          It is at your discretion to select a highlight or to invite the group
          to help choose a highlight in the moment.
        </p>
        <p>
          *If you have any background or context you can share regarding the
          highlight that you select, please share it with the group before
          playing the highlight.
        </p>
        <p>
          For example: “This comment was made in a conversation between mostly
          public educators in Wisconsin” or “this comment was made by a man who
          self-identified as…”
        </p>
      </>
    ),
    notes: (
      <>
        <p>
          If you are doing a full length LVN Conversation, you will have the
          opportunity to bring in the voice of someone from another
          conversation.
        </p>
        <p>
          Here you will find some powerful highlights that you can choose from
          that are related to the topic you selected. Sharing highlights allows
          participants to listen to, and reflect on, the perspective of someone
          who they may not have met or interacted with otherwise.
        </p>
      </>
    ),
  },
  {
    key: 'closingQuestion',
    slug: 'closing-question',
    display: 'Closing Questions',
    timeEstimate: '10 minutes',
    speakerInstructions: <></>,
    notes: (
      <>
        <p>
          The closing question(s) should provide a meaning-making opportunity
          for participants in which they are invited to think about how to take
          their learning forward from the conversation.
        </p>
      </>
    ),
  },
  {
    slug: 'wrap-up',
    display: 'Wrap-up',
    timeEstimate: '5 minutes',
    speakerInstructions: (
      <p>
        If someone doesn’t have an email address, try to get a phone number.
      </p>
    ),
    script: (
      <>
        <p>
          Those are all of the questions that we have for you. Do you have any
          closing thoughts that you’d like to share or other general reflections
          on the conversation?
        </p>
        <p>Do you have any questions for us?</p>
        <p>
          Just a reminder of what will happen with the thoughts you just shared.
          These recordings will be transcribed, indexed and shared with
          journalists, public officials, and participants in the Local Voices
          Network. If you’d like to listen to some of the other conversations
          we’ve been having please be sure to leave your email on the signup
          sheet.
        </p>
        <p>
          We encourage you to spread the word about the Local Voices Network. If
          you want to convene another group of friends to participate or know of
          others who want to participate, please go to lvn.org.{' '}
        </p>
        <p>Thank you so much for joining us and sharing your thoughts!</p>
      </>
    ),
    notes: <></>,
  },
  {
    slug: 'post-recording',
    display: 'Post-Recording Info',
    speakerInstructions: (
      <>
        <p>Press the “stop recording” button at the bottom of the screen.</p>
        <p>
          After you stop the recording, click “Poll” (usually in the bottom row
          of the screen) There is one saved poll.{' '}
        </p>
        <p>Click “launch poll.” </p>
        <p>When participants are done, click “end poll.”</p>
        <p>You can then click “end meeting.”</p>
      </>
    ),
    script: (
      <>
        <p>
          Let people know that you are available if anyone has any questions.
        </p>
        <p>
          Please let people know that we are able to redact comments if
          participants make this request after their conversation. If they would
          like something redacted, they should email:{' '}
          <a href="mailto:help@lvn.org">help@lvn.org</a>. Redactions should be
          submitted as soon as possible after reviewing the transcript for your
          conversation to ensure updates are made before the conversation
          public.
        </p>
        <p>
          Finally, before closing the call, let participants know that you would
          like to ask them to complete a brief survey. It should take less than
          2 minutes to complete. Please state:
          <strong className="d-block mt-2">
            LVN is requesting the information on this survey to ensure that we
            are meeting our goals of including diverse voices and perspectives.
            The data collected will not be tied to this conversation - it will
            only be used internally by Cortico staff for assessment purposes.
            The survey is entirely optional, but your participation would be
            greatly appreciated. After you complete the poll, please feel free
            to click “Leave Call” in the bottom right corner of your screen.
          </strong>
        </p>
      </>
    ),
    notes: <></>,
  },
];
