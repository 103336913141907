import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Step from './Step';

const TitleStep = ({ title, header, backPath, onNext, percent }) => {
  const [titleValue, setTitleValue] = React.useState('My LVN Script');
  function handleNext() {
    onNext(titleValue);
  }

  return (
    <Step
      title={title}
      header={header}
      backPath={backPath}
      onNext={handleNext}
      className="text-center"
      nextText="Make script"
      percent={percent}
    >
      <p>
        After the conversation, participants will have the opportunity to
        reflect on their conversation and ask questions.
      </p>
      <FormGroup>
        <Label style={{ width: '20rem' }}>
          Give your script a title:
          <Input
            type="text"
            value={titleValue}
            onChange={(e) => setTitleValue(e.currentTarget.value)}
          />
        </Label>
      </FormGroup>
    </Step>
  );
};
export default TitleStep;
