import React from 'react';
import cx from 'classnames';
import {
  useQueryParams,
  DelimitedArrayParam,
  StringParam,
} from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faStop } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import Layout from '../Layout/Layout';
import ScriptSection from '../ScriptSection/ScriptSection';
import { scriptSections } from '../constants';

import styles from './Script.module.scss';

const RecordingChunk = ({ type }) => (
  <div className="text-danger font-weight-bold mb-3 d-flex align-items-baseline">
    {type === 'start' ? (
      <>
        <FontAwesomeIcon icon={faCircle} className="mr-2 small" />
        Start recording
      </>
    ) : (
      <>
        <FontAwesomeIcon icon={faStop} className="mr-2 small" />
        Stop recording
      </>
    )}
  </div>
);

const Script = ({ data, highlightData }) => {
  const [chunks, setChunks] = React.useState({});
  // grab all keys, objs with keys have chunks set by URL
  const scriptSectionKeys = scriptSections.map((d) => d.key).filter((d) => d);
  const qp = scriptSectionKeys.map((sectionKey) => {
    return { [sectionKey]: DelimitedArrayParam };
  });
  const [query] = useQueryParams(
    Object.assign({ title: StringParam, welcome: StringParam }, ...qp)
  );
  const [clipboardBtnColor, setClipboardBtnColor] = React.useState('secondary');

  // TODO: how to handle section order
  // set visible chunks on mount
  React.useEffect(() => {
    const keys = scriptSections.map((d) => d.key).filter((d) => d);
    let chunksBySection = {};
    keys.forEach((key) => {
      const chunkIds = query[key];
      if (chunkIds && data[key]) {
        chunksBySection[key] = chunkIds
          .map((id) => data[key].find((d) => d.id === id))
          .filter((d) => d);
      } else if (chunkIds && highlightData) {
        chunksBySection[key] = chunkIds
          .map((id) => highlightData.find((d) => d.id === id))
          .filter((d) => d);
      }
    });
    setChunks(chunksBySection);
  }, [query, data, highlightData]);

  function handleCopyURL() {
    // dom el required to put stuff on clipboard
    let el = document.createElement('input');
    const url = window.location.href;
    document.body.appendChild(el);
    el.value = url;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setClipboardBtnColor('success');
    setTimeout(() => setClipboardBtnColor('secondary'), 500);
  }

  // TODO: container?
  return (
    <Layout>
      <div className="d-flex align-items-center justify-content-center">
        <div className={cx('shadow rounded card content-width', styles.panel)}>
          <div className="d-flex justify-content-between">
            <h2 className="mb-4">{query.title}</h2>
            <div>
              <a
                href={`/printable-script${window.location.search}`}
                className="btn btn-secondary mr-2 mb-2"
              >
                Printer-friendly version
              </a>
              <Button
                onClick={handleCopyURL}
                color={clipboardBtnColor}
                className="mb-2"
              >
                Copy URL
              </Button>
            </div>
          </div>
          {scriptSections.map((section) => {
            let bonusChunk;
            if (section.slug === 'welcome') {
              bonusChunk = <RecordingChunk type="start" />;
            } else if (section.slug === 'wrap-up') {
              bonusChunk = <RecordingChunk type="stop" />;
            }
            let extraProps = {};
            if (section.slug === 'welcome') {
              extraProps = { customWelcome: query.welcome };
            }
            if (section.slug === 'connecting-across-community') {
              return (
                <ScriptSection
                  key={section.slug}
                  title={section.display}
                  type="highlights"
                  chunks={chunks[section.key] || undefined}
                  script={section.script}
                  speakerInstructions={section.speakerInstructions}
                  timeEstimate={section.timeEstimate}
                />
              );
            }
            return (
              <div key={section.slug}>
                <ScriptSection
                  key={section.slug}
                  title={section.display}
                  chunks={chunks[section.key] || undefined}
                  script={section.script}
                  speakerInstructions={section.speakerInstructions}
                  timeEstimate={section.timeEstimate}
                  {...extraProps}
                />
                {bonusChunk}
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Script;
