import * as React from 'react';
import { useTitle } from 'react-use';

import Navbar from '../Navbar/Navbar';

function formatTitle(subtitle) {
  const baseTitle = 'LVN Script-o-matic';
  return subtitle ? `${subtitle} - ${baseTitle}` : baseTitle;
}

const Layout = ({ children, title }) => {
  useTitle(formatTitle(title));
  return (
    <>
      <Navbar />
      <main>{children}</main>
    </>
  );
};

export default Layout;
