import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import cx from 'classnames';

import Step from './Step';
import Tag from '../Tag/Tag';
import styles from './Step.module.scss';

const SingleChoiceStep = ({
  title,
  header,
  backPath,
  onNext,
  choices,
  selection,
  handleChangeSelect,
  children,
  legend,
  percent,
}) => {
  return (
    <Step
      title={title}
      header={header}
      backPath={backPath}
      onNext={onNext}
      percent={percent}
    >
      {children}
      <fieldset>
        {legend && (
          <legend className={cx(styles.legend, 'font-weight-bold mb-1')}>
            {legend}
          </legend>
        )}
        <FormGroup className="mt-1">
          {choices.map((choice) => {
            const isActiveChoice = selection
              ? selection[0] === choice.id
              : false;
            return (
              <div
                key={choice.id}
                className={cx(styles.choice, {
                  [styles.active]: isActiveChoice,
                })}
              >
                <Label>
                  <Input
                    type="radio"
                    onChange={() => handleChangeSelect([choice.id])}
                    checked={isActiveChoice}
                  />
                  {Array.isArray(choice.text) ? (
                    <div className={styles.content}>
                      {choice.text.map((text, i) => (
                        <p key={i}>{text}</p>
                      ))}
                    </div>
                  ) : (
                    <p className={styles.content}>{choice.text}</p>
                  )}
                </Label>
                <>
                  {choice.tags.map((tag) => (
                    <Tag
                      key={`${tag.key}-${choice.id}`}
                      tag={tag}
                      className={styles.content}
                    />
                  ))}
                </>
              </div>
            );
          })}
        </FormGroup>
      </fieldset>
    </Step>
  );
};

export default SingleChoiceStep;
