import React from 'react';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Layout from '../Layout/Layout';
import styles from './Step.module.scss';
import ScriptProgress from './ScriptProgress';

const Step = ({
  children,
  title,
  header,
  backPath,
  onNext,
  nextPath,
  nextText = 'Next',
  className,
  percent,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);

  return (
    <Layout title={title}>
      <Container className="my-4 d-flex align-items-center justify-content-center">
        <div className={cx('content-width', className)}>
          <h1 className="text-center">{header}</h1>
          {percent && <ScriptProgress percent={percent} />}
          <div className={cx(styles.children)}>{children}</div>
          {backPath && (
            <Button
              tag={Link}
              to={backPath}
              size="lg"
              color="primary"
              className="float-left"
            >
              Back
            </Button>
          )}
          {onNext && (
            <Button
              onClick={onNext}
              size="lg"
              className="float-right"
              color="primary"
            >
              {nextText}
            </Button>
          )}
          {nextPath && (
            <Button
              tag={Link}
              to={nextPath}
              size="lg"
              className="float-right"
              color="primary"
            >
              {nextText}
            </Button>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default Step;
