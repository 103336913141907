import React from 'react';
import cx from 'classnames';
import { ProgressBar, Step as ProgressStep } from 'react-step-progress-bar';

import 'react-step-progress-bar/styles.css';
import styles from './Step.module.scss';

const steps = Array.from(Array(9).keys());

const ScriptProgress = ({ percent }) => {
  return (
    <div className="my-4">
      <ProgressBar percent={percent} filledBackground="#6351d9">
        {steps.map((step) => (
          <ProgressStep key={step}>
            {({ accomplished, index }) => (
              <div
                className={cx(styles.indexedStep, {
                  [styles.accomplished]: accomplished,
                })}
              >
                {index + 1}
              </div>
            )}
          </ProgressStep>
        ))}
      </ProgressBar>
    </div>
  );
};

export default ScriptProgress;
