import * as React from 'react';

class LvnLogo extends React.Component {
  static defaultProps = {
    width: 44,
  };

  render() {
    const { width } = this.props;

    return (
      <svg className="LvnLogo" width={width} viewBox="0 0 886 886">
        <g
          transform={`translate(${
            (886 - 768) / 2
          } 0)`} /* originally was 768x886, but we made it square */
        >
          <polygon
            className="LvnLogo--line"
            fill="#6351D9"
            points="171.51 885.27 171.51 710.61 0.31 710.61 0.31 0.73 55 0.73 55 655.92 226.2 655.92 226.2 791.32 389.56 655.92 767.67 655.92 767.67 710.61 407.59 710.61 196.79 885.05"
          />
          <path
            className="LvnLogo--letter"
            d="M172.25,538.63 L172.25,1.72 L230.31,1.72 L230.31,483 C230.31,483.07 230.36,483.12 230.43,483.12 L339.05,483.12 C339.12,483.12 339.17,483.17 339.17,483.24 L339.17,538.64 C339.17,538.71 339.12,538.76 339.05,538.76 L172.37,538.76 C172.31,538.75 172.25,538.7 172.25,538.63 Z"
            fill="#000000"
          />
          <path
            className="LvnLogo--letter"
            d="M442.17,538.75 L383.51,538.75 C383.45,538.75 383.4,538.71 383.39,538.65 L304.4,1.85 C304.39,1.78 304.45,1.71 304.52,1.71 L364.76,1.71 C364.82,1.71 364.87,1.76 364.88,1.82 L414.06,412.15 L468.88,1.82 C468.89,1.76 468.94,1.72 469,1.72 L525.2,1.72 C525.27,1.72 525.33,1.79 525.32,1.86 L442.31,538.66 C442.28,538.71 442.23,538.75 442.17,538.75 Z"
            fill="#000000"
          />
          <path
            className="LvnLogo--letter"
            d="M725.04,538.75 C724.99,538.75 724.94,538.72 724.93,538.67 L633.83,267.01 L608.03,180.73 L608.03,538.64 C608.03,538.71 607.98,538.76 607.91,538.76 L553.32,538.76 C553.25,538.76 553.2,538.71 553.2,538.64 L553.2,1.84 C553.2,1.77 553.25,1.72 553.32,1.72 L605.53,1.72 C605.58,1.72 605.63,1.75 605.64,1.8 L688.67,277.49 L712.86,364.58 L712.86,1.84 C712.86,1.77 712.91,1.72 712.98,1.72 L767.57,1.72 C767.64,1.72 767.69,1.77 767.69,1.84 L767.69,538.64 C767.69,538.71 767.64,538.76 767.57,538.76 L725.04,538.76 L725.04,538.75 Z"
            fill="#000000"
          />
        </g>
      </svg>
    );
  }
}

export default LvnLogo;
