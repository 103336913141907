import React from 'react';
import { Container } from 'reactstrap';

import Layout from '../Layout/Layout';
import Tag from '../Tag/Tag';

// { title: '', queryString: '', description: '', tags: [] },

const premadeScripts = [
  {
    title: 'Mathias - The Script',
    queryString:
      '?storyForStarters=0221279c-9c74-412c-8644-f3c7098a8e20&communityFocusedConversation=6c4582f7-0e52-4bf0-a761-60b1636382cf_1951d127-315b-4bfe-b4db-d81d6ed1e3ea_2694badb-ba51-48bd-be65-9a6b41d0aca8&connectingAcrossCommunity=643310_316785_513994&closingQuestion=d9f56873-f303-4c7e-9016-fe61a63f7e07&title=THE%20SCRIPT%20',
    description: 'A good script to try out the tool',
    tags: ['economicDevelopment'],
  },
  {
    title: "Alex's Civic Engagment Script",
    queryString:
      '?storyForStarters=b7abc6ed-1960-41ce-a5c1-e0965f73cdf9&communityFocusedConversation=2694badb-ba51-48bd-be65-9a6b41d0aca8&connectingAcrossCommunity=141752&closingQuestion=640dc079-2f51-4bbe-9137-6c31dfe18c9c&title=Alex%27s%20Civic%20Engagement%20Script',
    description: 'A great script for first time facilitators.',
    tags: ['civicEngagement'],
  },
  {
    title: "Alex's Script on Aging",
    queryString:
      '?storyForStarters=b7abc6ed-1960-41ce-a5c1-e0965f73cdf9&communityFocusedConversation=2694badb-ba51-48bd-be65-9a6b41d0aca8&connectingAcrossCommunity=141752&closingQuestion=640dc079-2f51-4bbe-9137-6c31dfe18c9c&storyForStarters=58e27674-cdd4-4adb-9286-e60d6796f247&communityFocusedConversation=33d6c358-aaac-48a7-8001-5ce0e65cbc18&connectingAcrossCommunity=363432&closingQuestion=640dc079-2f51-4bbe-9137-6c31dfe18c9c_f76be85c-234d-4e10-8171-60b6ada540c2&title=Alex%27s%20Script%20on%20Aging',
    description: 'A script to support conversations with older adults.',
    tags: ['aging'],
  },
];

const ScriptCard = ({ script, i }) => {
  return (
    <div className="border rounded mb-3 p-3">
      <div className="mb-1">
        <a href={`/script${script.queryString}`}>{script.title}</a>
        {script.description && (
          <span className="mb-0"> - {script.description}</span>
        )}
      </div>
      <div>
        {script.tags.map((tag) => (
          <Tag key={`${tag.key}-${i}`} tag={tag} />
        ))}
      </div>
    </div>
  );
};

const Scripts = () => {
  return (
    <Layout title="Pre-made Scripts">
      <Container className="my-4 d-flex align-items-center justify-content-center">
        <div className="content-width">
          <h1>Pre-made Scripts</h1>
          <PadletEmbed />
          <hr />
          <p>Some scripts made with the Script-o-matic:</p>
          {premadeScripts.map((script, i) => (
            <ScriptCard script={script} key={i} />
          ))}
        </div>
      </Container>
    </Layout>
  );
};

const PadletEmbed = () => {
  const embedHTML =
    '<div class="padlet-embed" style="border:1px solid rgba(0,0,0,0.1);border-radius:2px;box-sizing:border-box;overflow:hidden;position:relative;width:100%;background:#F4F4F4"><p style="padding:0;margin:0"><iframe src="https://padlet.com/embed/lm3g12m84htik90g" frameborder="0" allow="camera;microphone;geolocation" style="width:100%;height:608px;display:block;padding:0;margin:0"></iframe></p><div style="padding:8px;text-align:right;margin:0;"><a href="https://padlet.com?ref=embed" style="padding:0;margin:0;border:none;display:block;line-height:1;height:16px" target="_blank"><img src="https://padlet.net/embeds/made_with_padlet.png" width="86" height="16" style="padding:0;margin:0;background:none;border:none;display:inline;box-shadow:none" alt="Made with Padlet"></a></div></div>';
  return <div dangerouslySetInnerHTML={{ __html: embedHTML }} />;
};

export default Scripts;
