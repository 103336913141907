import React from 'react';
import cx from 'classnames';

// probably better way to do this :)
import { data as tagData } from '../data/tags';
import styles from './Tag.module.scss';

const Tag = ({ tag, className }) => {
  const tagObj = tagData.find((d) => d.key === tag);
  if (!tagObj) {
    return null;
  }
  return (
    <span className={cx(styles.tag, 'px-2 mr-2 font-weight-bold', className)}>
      {tagObj.display}
    </span>
  );
};

export default Tag;
