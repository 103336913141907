import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import cx from 'classnames';

import Step from './Step';
import styles from './Step.module.scss';

function toggleArrayItem(arr, item) {
  return arr.includes(item)
    ? arr.filter((i) => i !== item) // remove item
    : [...arr, item]; // add item
}

const StartStep = ({ percent, topics, setActiveTopics, activeTopics }) => {
  return (
    <Step
      title="Get started"
      header="Welcome the to LVN Script-o-matic!"
      nextPath="/welcome"
      percent={percent}
    >
      <p>
        The script-o-matic will walk you through each section of the LVN script,
        explain each section, and allow you to select your own prompts. After
        your script is created, you can save the link and return to it.
      </p>
      <fieldset>
        <legend className={cx(styles.legend, 'font-weight-bold mb-1')}>
          To get started making your script, select topics you'd like to cover
          in your conversation:
        </legend>
        <FormGroup className="d-flex flex-column">
          {topics.map((topic) => {
            const isActiveTopic = !!activeTopics.find((d) => d === topic);
            return (
              <Label
                key={topic.key}
                className={cx(styles.choice, {
                  [styles.active]: isActiveTopic,
                })}
              >
                <Input
                  type="checkbox"
                  onChange={() =>
                    setActiveTopics(toggleArrayItem(activeTopics, topic))
                  }
                  checked={isActiveTopic}
                />
                <span className={styles.content}>{topic.display}</span>
              </Label>
            );
          })}
        </FormGroup>
      </fieldset>
    </Step>
  );
};

export default StartStep;
