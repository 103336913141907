import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import cx from 'classnames';

import styles from './ScriptSection.module.scss';

const Script = ({ editable, script, customWelcome, setCustomWelcome }) => {
  const [editingWelcome, setEditingWelcome] = React.useState(false);
  const showCustomWelcome = customWelcome !== undefined;

  function handleInputChange(e) {
    setCustomWelcome(e.target.value);
  }

  function handleCustomWelcomeSave() {
    setEditingWelcome(false);
  }

  if (editingWelcome) {
    return (
      <div>
        <textarea
          className="w-100"
          onChange={handleInputChange}
          value={customWelcome}
        />
        <div className="d-flex">
          <p className="small">
            We recommend adding your organization's name and an additional
            sentence that talks about your project and why you're hosting these
            conversations.
          </p>
          <Button
            onClick={handleCustomWelcomeSave}
            className="float-right mt-2"
            size="sm"
          >
            Save
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {showCustomWelcome ? <p>{customWelcome}</p> : script}
      {editable && (
        <Button
          onClick={() => setEditingWelcome(true)}
          className="float-right"
          size="sm"
        >
          Edit
        </Button>
      )}
    </>
  );
};

const ScriptSection = ({
  script,
  chunks,
  title,
  className,
  speakerInstructions,
  timeEstimate,
  type,
  labeled = false,
  editable,
  setCustomWelcome,
  customWelcome,
  printable,
}) => {
  if (!script && (!chunks || chunks.length === 0)) {
    return null;
  }

  return (
    <section className={cx('mb-4', className)}>
      <div className="d-flex justify-content-between align-items-baseline">
        <h3 className="mb-0">{title}</h3>
        <p className="text-gray-700 small float-right mb-0">{timeEstimate}</p>
      </div>
      {labeled && (
        <Row className="small mb-0">
          <Col xs="3">Speaker instruction</Col>
          <Col>Script</Col>
        </Row>
      )}
      <Row className="p-2 mb-1 border rounded">
        <Col xs="3">
          <small>{speakerInstructions}</small>
        </Col>
        <Col className={cx(styles.scriptContent, 'border-left')}>
          <Script
            script={script}
            editable={editable}
            customWelcome={customWelcome}
            setCustomWelcome={setCustomWelcome}
          />
          {chunks && (
            <>
              {chunks.map((chunk) => (
                <div key={chunk.id}>
                  {type === 'highlights' ? (
                    <>
                      <p className="mb-2">{chunk.description}</p>
                      {printable ? (
                        <p className="mb-3">
                          View this highlight online:{' '}
                          <a
                            href={`https://lvn.org/highlight/${chunk.id}`}
                          >{`https://lvn.org/highlight/${chunk.id}`}</a>
                        </p>
                      ) : (
                        <iframe
                          title={`LVN highlight ${chunk.id}`}
                          src={`https://embed.lvn.org/?hid=${chunk.id}`}
                          className={cx(styles.embed, 'w-100')}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {Array.isArray(chunk.text) ? (
                        <div>
                          {chunk.text.map((text, i) => (
                            <p key={i}>{text}</p>
                          ))}
                        </div>
                      ) : (
                        <p>{chunk.text}</p>
                      )}
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    </section>
  );
};

export default ScriptSection;
