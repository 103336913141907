import React from 'react';
import {
  Container,
  Nav,
  Navbar as NavbarReactstrap,
  NavbarBrand,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import LvnLogo from '../LvnLogo';

const Navbar = () => {
  return (
    <NavbarReactstrap color="white">
      <Container className="content-width">
        <NavbarBrand href="https://lvn.org" tag={'a'}>
          <LvnLogo />
        </NavbarBrand>
        <Nav navbar className="flex-row">
          <NavItem>
            <NavLink tag={Link} to="/">
              Make a script
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/scripts">
              Pre-made scripts
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </NavbarReactstrap>
  );
};

export default Navbar;
